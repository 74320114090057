<template>
  <div class="dashboard">
    <div v-if="isMobileApp" class="mobile-overlay"></div>
    <div class="divider"></div>
    <ProfileExperience
      v-if="$root.isMobile && achievements"
      :firstName="profile ? profile.firstName : ''"
      :avatar="avatar"
      :experience="profile ? profile.experience : null"
      :achievements="achievements"
      :sportIndexes="sportIndexes"
      :hideEdit="true"
      :isMobileApp="isMobileApp"
      :steps="steps"
      @set-target-steps="onShowTargetSteps"
    />

    <Menu @feedback="showNotifications = false" />

    <!-- <SettingUpGuide
      v-if="$root.isMobile"
      v-bind="settingUpGuideOptions"
      :state="bannerTracker"
      @hide="bannerTrackerClose()"
    />

    <SettingUpGuide
      v-if="$root.isMobile && !isTrackerConnected"
      v-bind="settingUpGuideOptions"
    /> -->
    <SettingUpGuide
      v-if="$root.isMobile"
      v-bind="settingUpGuideOptions"
      @click="onModalTracker"
      :state="bannerTracker"
      @hide="bannerTrackerClose()"
    />

    <div class="divider" v-if="!$root.isMobile"></div>

    <ProfileExperience
      v-if="!$root.isMobile && achievements"
      :firstName="profile ? profile.firstName : ''"
      :avatar="avatar"
      :experience="profile ? profile.experience : null"
      :achievements="achievements"
      :sportIndexes="sportIndexes"
      :hideEdit="true"
      :isMobileApp="isMobileApp"
      :steps="steps"
      @set-target-steps="onShowTargetSteps"
    />

    <SettingUpGuide
      v-if="showTrainings"
      v-bind="settingTrainingPlanOptions"
      @click="onModalTrainingsOpen"
      :state="bannerTraining"
      @hide="bannerTreanningClose()"
    />

    <SettingUpGuide
      v-if="isShowMarketBanner"
      v-bind="settingMarketOptions"
      @click="onMarketOpen"
      :state="bannerMarket"
      @hide="bannerMarketClose()"
    />

    <SettingUpGuide
      v-if="!$root.isMobile"
      v-bind="settingUpGuideOptions"
      @click="onModalTracker"
      :state="bannerTracker"
      @hide="bannerTrackerClose()"
    />

    <SectionContainer
      title="Задания"
      icon="file-done"
      @goto="onGoTo('TasksAvailable')"
    >
      <div class="stats">
        <div class="stats__card" @click.stop="$router.push({ name: 'TasksInProgress' }).catch(() => {})">
          <div class="stats__card-count">{{ tasksInProgress ? tasksInProgress.total : '' }}</div>
          <div class="stats__card-title">Выполняются</div>
        </div>
        <div class="stats__card" @click.stop="$router.push({ name: 'TasksCompleted' }).catch(() => {})">
          <div class="stats__card-count">{{ tasksCompleted ? tasksCompleted.total : '' }}</div>
          <div class="stats__card-title">Выполнены</div>
        </div>
      </div>
      <div class="recommended">
        <div class="recommended__title" v-if="tasksAvailable ? tasksAvailable.total > 0 : false">Подобрано для вас</div>
        <div class="recommended__slider">
          <Slider
            v-if="tasksAvailable ? tasksAvailable.total > 0 : false"
            :cols="cols"
            :noPagination="noPagination"
            class="tasks-slider"
          >
            <TaskCard
              v-for="(task, i) in tasksAvailable.values"
              :key="i"
              :stateLabels="stateLabels"
              :timeLabels="timeLabels"
              :styleLabels="styleLabels"
              :image="task.images.mobile"
              :action="{ func: () => onShowTask(i) }"
              type="coins"
              state="available"
              :title="task.title"
              :subtitle="task.shortDescription"
              :cost="getTaskRewardRange(task)"
              :date="`${task.endDate}`"
              :itemsTotal="0"
              :itemsDone="0"
              :complexity="task.complexity"
              :isSpecial="task.isSpecial || false"
              :isDaily="task.isDaily || false"
              :isRepeat="false"
            ></TaskCard>
          </Slider>
        </div>
      </div>
    </SectionContainer>

    <div v-if="$root.isMobile" class="banner">
      <router-link to="/calendar"><img :src="calendarData && calendarData.calendarImages ? calendarData.calendarImages.mobile : ''"></router-link>
    </div>
    <div v-if="!$root.isMobile" class="banner">
      <router-link to="/calendar"><img :src="calendarData && calendarData.calendarImages ? calendarData.calendarImages.desktop : ''"></router-link>
    </div>

    <SectionContainer
      title="Маркет"
      icon="shopping-bag"
      @goto="onGoTo('Market')"
    >
      <div class="rewards">
        <div class="rewards__slider">
          <Slider
            v-if="promotions ? promotions.total > 0 : false"
            :cols="cols"
            :noPagination="noPagination"
            class="rewards-slider"
          >
            <RewardCard
              v-for="(promotion, i) in promotions.values"
              :key="i"
              :image="promotion.images ? promotion.images.mobile : ''"
              :action="{ func: () => onAction(i) }"
              type='coins'
              :stateLabels="rewardsStateLabels"
              :title="promotion.name"
              :subtitle="promotion.subTitle"
              :cost="promotion.priceInPoints"
              :compactMobile="$root.isMobile"
            ></RewardCard>
          </Slider>
        </div>
      </div>
    </SectionContainer>

    <div v-if="bannerVk && $root.isMobile" class="banner">
      <a :href="bannerVk ? bannerVk.redirectUrl : ''" target="_blank">
        <img :src="bannerVk ? bannerVk.mobileImageUrl : ''" />
      </a>
    </div>
    <div v-if="bannerVk && !$root.isMobile" class="banner">
      <a :href="bannerVk ? bannerVk.redirectUrl : ''" target="_blank">
        <img :src="bannerVk ? bannerVk.desktopImageUrl : ''" />
      </a>
    </div>

    <SectionContainer
      v-if="hasNominations"
      title="Рейтинг"
      icon="trophy"
      @goto="onGoTo('Nominations')"
    >
      <div class="nominations">
        <div v-if="isTeamsEnabled && showTeamRatings" class="nominations__types">
          <Button
            v-for="(item, i) in nominationTypes"
            :key="i"
            :variant="i === selectedNominationType ? 'primary' : 'secondary'"
            :disabled="isRatingTypeDisabled(i)"
            size="md"
            mobile
            @click="onChangeNominationType(i)"
          >
            {{ item }}
          </Button>
        </div>
        <div
          class="nominations__slider-container"
          :style="{
            'opacity': (hiddenNominationsSlider ? '0' : '1'),
            'min-height': (nominationsSliderHeight ? nominationsSliderHeight + 'px' : 'auto'),
          }"
          :id="nominationsSliderId"
        >
          <div v-if="!nominationsUpdating && selectedNominationType === 0" class="nominations__slider">
            <Slider
              v-if="nominationsActive ? nominationsActive.total > 0 : false"
              cols="1"
              :slidesPerView="1"
              :loop="true"
              :navigationOffset="nominationsNavigationOffset"
              :paginationOffset="nominationsPaginationOffset"
              class="nominations-slider"
            >
              <NominationCard
                v-for="item in nominationsActive.values"
                :key="item.nominationId"
                type="personal"
                :state="nominationsState"
                :image="getNominationImage(item)"
                :nominationText="getNominationDates(item)"
                :lockedText="{ text: 'Для участия необходимо', link: 'подключить трекер' }"
                :title="item.title"
                :rules="{ text: 'Правила', action: () => onShowRules(item) }"
                :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
                :ratingText="{ general: 'Текущий рейтинг', personal: 'Вы в рейтинге' }"
                :values="getNominationRating(item)"
                @show-details="onShowRating(item)"
                @locked-click="onShowOnboarding()"
              ></NominationCard>
            </Slider>
          </div>
          <div v-if="!nominationsUpdating && selectedNominationType === 1" class="nominations__slider">
            <Slider
              v-if="teamNominationsActive ? teamNominationsActive.total > 0 : false"
              cols="1"
              :slidesPerView="1"
              :loop="true"
              :navigationOffset="nominationsNavigationOffset"
              :paginationOffset="nominationsPaginationOffset"
              filterByGroupId="1"
              class="nominations-slider"
            >
              <NominationCard
                v-for="item in teamNominationsActive.values"
                :key="item.nominationId"
                type="personal"
                state="in-progress"
                class="team-rating"
                data-group-id="1"
                :nominationText="getNominationDates(item)"
                :lockedText="{ text: 'Для участия необходимо', link: 'подключить трекер' }"
                :title="item.title"
                :rules="{ text: 'Правила', action: () => onShowRules(item) }"
                :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
                :ratingText="{ general: 'Текущий рейтинг', personal: 'Команда в рейтинге' }"
                :values="getNominationRating(item, true)"
                @show-details="onShowRating(item, true)"
              ></NominationCard>
            </Slider>
          </div>
        </div>
      </div>
    </SectionContainer>
    <div v-if="bannerCommunity && $root.isMobile" class="banner">
      <a :href="bannerCommunity ? bannerCommunity.redirectUrl : ''" target="_blank">
        <img :src="bannerCommunity ? bannerCommunity.mobileImageUrl : ''" />
      </a>
    </div>
    <div v-if="bannerCommunity &&!$root.isMobile" class="banner">
      <a :href="bannerCommunity ? bannerCommunity.redirectUrl : ''" target="_blank">
        <img :src="bannerCommunity ? bannerCommunity.desktopImageUrl : ''" />
      </a>
    </div>

    <SectionContainer
      v-if="isShowGoal"
      title="Общая цель"
      icon="goal"
      class="bg-gray"
      @goto="onShowDistance()"
    >
      <div class="goal">
        <div class="goal__stats">
          <div class="stats__personal">
            <div class="stats__value">{{ totalDistance }}<span> км</span></div>
            <div class="stats__text">Ваш вклад</div>
          </div>
          <div class="stats__general">
            <div class="stats__value">{{ completedDistance }}<span> км</span></div>
            <div class="stats__text">Общий вклад</div>
          </div>
        </div>
        <div class="goal__compare">
          <div class="compare__text">Ваш вклад больше, чем у <span>{{ contributionPercentage }}%</span> участников</div>
          <div
            class="compare__graph"
            :style="{ 'background-image': `url('${ $root.isMobile ? goalGraphMobile : goalGraph }')` }"
          >
            <img
              :src="goalTriangle"
              class="graph__marker"
              :style="{
                'left': `calc(${ contributionPercentage-1 }%)`,
                'bottom': `calc(${ goalCoords[contributionPercentage] }px + 8px)`,
              }"
            />
          </div>
        </div>
      </div>
    </SectionContainer>

    <SectionContainer
      id="dashboard-activity"
      title="Активность"
      icon="pulse"
      @goto="onGoTo('Activities')"
    >
      <div class="activity">
        <div class="activity__slider">
          <Slider
            v-if="!loading"
            cols="1"
            :navigationOffset="activityNavigationOffset"
            :paginationOffset="activityPaginationOffset"
            :slidesPerView="1"
            :autoHeight="true"
            class="activity-slider"
            @indexChanged="onSliderIndexChaged"
          >
            <ActivityCard
              v-for="(activity, i) in activities"
              :key="i"
              :title="activity.date"
              :items="activity.items"
            ></ActivityCard>
            <ActivityCard
              title="Более ранние записи в разделе <a href='/activities'>«Активность»</a>"
              isLast
              @show-all="onGoTo('Activities')"
            ></ActivityCard>
          </Slider>
          <div class="activity__loader" v-if="loading">
            <Loader size="24" />
          </div>
        </div>
      </div>
    </SectionContainer>

    <div class="bottom"></div>

    <div v-if="showNotifications">
      <RrNotifications />
    </div>

    <div class="sneak-bar" v-if="showQuestionnaireSaved || showInstallNotification">
      <Alert
        v-if="showQuestionnaireSaved"
        text="Ваши ответы успешно сохранены."
        actionText="Понятно"
        state="success"
        closable
        autoClose
        stacked
        @close="showQuestionnaireSaved = false"
      ></Alert>
      <Alert
        v-if="showInstallNotification && !isSpecialSettingsMp"
        class="install-notification"
        :text="installNotificationText"
        noIcon
        noAction
        @close="showInstallNotification = false"
      ></Alert>
    </div>
    <div class="sneak-bar">
      <Alert
        v-if="showAlertTargetSteps"
        text="Цель изменена!"
        state="success"
        noAction
        :closable="true"
        :autoClose="true"
      ></Alert>
    </div>

    <WelcomeModal
      v-show="welcomeModal"
      :active="welcomeModal"
      :localizedText="welcomeLocalizedText"
      :isMobileApp="isMobileApp"
      @close="onWelcomeClosed"
    />

    <QuestionnaireModal
      :active="questionnaireModal"
      :localizedText="questionnaireLocalizedText"
      :isMobileApp="isMobileApp"
      @save="onQuestionnaireSave"
      @close="onQuestionnaireClosed"
    />

    <TaskDetailsModal
      :active="taskDetailsModal"
      :state="taskDetailsState"
      :modalOptions="taskDetailsOptions"
      :rating="taskRating"
      @close="onTaskDetailsClose"
      @start="onStart"
    />

    <RulesPrizesModal
      :active="rulesPrizesModal"
      :image="rulesPrizesImage"
      :title="rulesPrizesTitle"
      :subtitle="rulesPrizesSubtitle"
      :nominationId="rulesPrizesNominationId"
      :isTeam="rulesPrizesIsTeam"
      state="personal"
      :tabs="rulesPrizesTabs"
      :activeTab="rulesPrizesTabActive"
      :isMobileApp="isMobileApp"
      @close="onRulesPrizesClosed"
    />

    <DistanceCharityModal
      :active="distanceCharityModal"
      :type="distanceCharityType"
      :localizedText="distanceCharityLocalizedText"
      :isMobileApp="isMobileApp"
      moreLink=""
      @close="onDistanceCharityModalClosed"
    />
    <TrainingsModal
      :active="modalTrainings"
      :isMobileApp="isMobileApp"
      @close="modalTrainings = false"
      @openModaInfo="onModalTrainingsInfoOpen"
      @startTest="onStartQuiz"
    />
    <TrainingsInfoModal
      :active="modalTrainingsInfo"
      :isMobileApp="isMobileApp"
      @close="modalTrainingsInfo = false"
      @startTest="onStartQuiz"
    />
    <TrainingsFinishModal
      :active="modalTrainingsFinish"
      @close="modalTrainingsFinish = false"
      @showPrice="onStartPrice"
    />
    <TrainingsPriceModal
      :active="modalTrainingsPrice"
      @close="modalTrainingsPrice = false"
      @openPlan="modalTrainingsPrice = false"
      @openPlanCoach="modalTrainingsPrice = false"
      @clickSendTrenningsPurchase="clickSendTrenningsPurchase($event)"
    />
    <QuizModal
      :active="quizModal"
      :options="quizOptions"
      @close="onQuizClose"
      @back="onQuizBack"
      @quizEnd="onQuizEnd"
    />
    <TrainingsSuccessModal
      v-if="getEmail"
      :active="modalTrainingsSuccess"
      @close="closeModalTrainingsSuccess"
      :emailProfile="getEmail"
    />
    <Modal
      name="target-steps"
      title="Моя цель шагов"
      :active="showTargetSteps"
      :class="{ 'for-mobile-app show-modal-close': isMobileApp }"
      :noTransition="true"
      @close.prevent="onTargetStepsClose"
      class="target-steps__modal"
    >
      <Link @click="showTargetStepsInfo = true" text="Как выбрать количество шагов"></Link>
      <div class="target-steps__modal-count">
        <Button
          @click="onDiminishCount"
          variant="secondary"
          size="lg"
          :disabled="stepsCount <= 1000"
        >
          <Icon name="minus" size="l"></Icon>
        </Button>
        <div class="target-steps__modal-input">
          <Input
            v-model="stepsCount"
            name="text"
            readonly
          ></Input>
        </div>
        <Button
          @click="onAddCount"
          variant="secondary"
          size="lg"
        >
          <Icon name="plus" size="l"></Icon>
        </Button>
      </div>
      <div class="target-steps__modal-bottom">
        <Button
          @click="onSetTarget"
          variant="primary"
          size="lg"
          text="Установить цель"
          mobile
        ></Button>
        <span>Цель применится к сегодняшнему и&nbsp;последующим дням</span>
      </div>
    </Modal>
    <Modal
      name="target-steps-info"
      title="Как выбрать количество шагов"
      :img="targetStepsInfoImg"
      :active="showTargetStepsInfo"
      :class="{ 'for-mobile-app show-modal-close': isMobileApp }"
      :noTransition="true"
      @close.prevent="onTargetStepsInfoClose"
      class="target-steps-info__modal"
    >
      <ul>
        <li>менее 5000 шагов в день — характеризуют образ жизни как «сидячий»;</li>
        <li>от 5000 до 7499 шагов в день – показатель не включает спорт и физические нагрузки и считается «малоэффективным»;</li>
        <li>от 7500 до 9999 шагов в день – включает некоторые физические нагрузки или ходьбу (и/или работу, требующую больше ходьбы) и считается «отчасти активным»;</li>
        <li>10000 и более шагов в день характеризуют образ жизни человека как «активный».</li>
      </ul>
      <!-- <Button
        text="Узнать больше"
        variant="secondary"
        tag="button"
        :target="!isMobileApp ? '_blank' : ''"
        href="#"
        size="lg"
        mobile
      /> -->
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import TaskCard from '@sc-component-library/task-card/src/main';
import RewardCard from '@sc-component-library/reward-card/src/main';
import NominationCard from '@sc-component-library/nomination-card/src/main';
import Alert from '@rr-component-library/alert/src/main';
import Input from '@rr-component-library/input/src/main';
import TrainingsModal from '@/components/TrainingsModals/TrainingsModal.vue';
import TrainingsInfoModal from '@/components/TrainingsModals/TrainingsInfoModal.vue';
import TrainingsFinishModal from '@/components/TrainingsModals/TrainingsFinishModal.vue';
import TrainingsPriceModal from '@/components/TrainingsModals/TrainingsPriceModal.vue';
import TrainingsSuccessModal from '@/components/TrainingsModals/TrainingsSuccessModal.vue';
import WelcomeModal from '@/components/WelcomeModal/WelcomeModal.vue';
import QuestionnaireModal from '@/components/QuestionnaireModal/QuestionnaireModal.vue';
import QuizModal from '@/components/QuizModal/QuizModal.vue';
import Menu from '@/components/Menu/Menu.vue';
import SectionContainer from '@/components/SectionContainer/SectionContainer.vue';
import ActivityCard from '@/components/ActivityCard/ActivityCard.vue';
import TaskDetailsModal from '@/components/TaskDetailsModal/TaskDetailsModal.vue';
import ProfileExperience from '@/components/ProfileExperience/ProfileExperience.vue';
import RulesPrizesModal from '@/components/RulesPrizesModal/RulesPrizesModal.vue';
import DistanceCharityModal from '@/components/DistanceCharityModal/DistanceCharityModal.vue';
import SettingUpGuide from '@/components/SettingUpGuide/SettingUpGuide.vue';
import RrNotifications from '@/components/Notifications/Notifications.vue';
import Coin from '@/assets/img/coin.png';
import ActivityCover3 from '@/assets/img/activity-card/updated.svg';
import ActivityCoverXP from '@/assets/img/activity-card/xp.svg';
import iOSShare from '@/assets/img/ios-share.svg';
import AchievementsAll from '@/assets/data/achievements';
import GoalCoords from '@/assets/data/goalCoords';
import Avatars from '@/assets/data/avatars';
import WHOImage from '@/assets/img/index-ofp/who.svg';
import { scrollToId, formatDateRange } from '@/assets/js/helpers';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'Dashboard',
  components: {
    TaskCard,
    RewardCard,
    WelcomeModal,
    TrainingsModal,
    TrainingsInfoModal,
    TrainingsFinishModal,
    TrainingsPriceModal,
    QuestionnaireModal,
    TrainingsSuccessModal,
    QuizModal,
    Menu,
    SectionContainer,
    ActivityCard,
    TaskDetailsModal,
    ProfileExperience,
    NominationCard,
    Alert,
    Input,
    RulesPrizesModal,
    DistanceCharityModal,
    SettingUpGuide,
    RrNotifications,
  },
  data() {
    return {
      showTargetSteps: false,
      stepsCount: 4000,
      showTargetStepsInfo: false,
      showAlertTargetSteps: false,
      targetStepsInfoImg: WHOImage,
      bannerTraining: false,
      bannerTracker: false,
      bannerMarket: false,
      modalTrainings: false,
      modalTrainingsInfo: false,
      modalTrainingsFinish: false,
      modalTrainingsPrice: false,
      modalTrainingsSuccess: false,
      quizModal: false,
      quizOptions: null,
      stateLabels: {
        available: 'до ', inProgress: 'Выполняется', checking: 'Проверяется', completed: 'Выполнено', failed: 'Вы не успели :(',
      },
      styleLabels: {
        special: 'Особое задание', daily: 'Ежедневное', repeat: 'Многоразовое',
      },
      timeLabels: { days: 'д', hours: 'ч' },
      rewardsStateLabels: {
        available: 'В наличии', count: '5 шт.',
      },
      achievementsAll: AchievementsAll,
      nominationTypes: ['Личный', 'Командный'],
      selectedNominationType: null,
      nominationsState: 'in-progress',
      hiddenNominationsSlider: false,
      nominationsSliderId: `nominations__slider-container${Math.random().toString().replace('.', '')}`,
      nominationsSliderHeight: 0,
      nominationsUpdating: false,
      rulesPrizesModal: false,
      rulesPrizesImage: '',
      rulesPrizesTitle: '',
      rulesPrizesSubtitle: '',
      rulesPrizesNominationId: '',
      rulesPrizesIsTeam: false,
      rulesPrizesTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
        {
          title: 'Рейтинг',
          enabled: true,
        },
      ],
      rulesPrizesTabActive: 0,
      activities: [],
      welcomeModal: false,
      welcomeLocalizedText: {
        title: '',
        subtitle: '',
        subtitleMarket: `Вам начисленно <b><img src="${Coin}"/> 200</b> приветственных баллов. <br/> Можете потратить их на приятный бонус в маркете`,
        actionPrimary: { text: 'Заполнить анкету', action: this.onWelcomeActionPrimary },
        actionSecondary: { text: 'Напомнить позже', action: this.onWelcomeActionSecondary },
        actionMarket: { action: this.onWelcomeActionMarket },
      },
      questionnaireModal: false,
      questionnaireLocalizedText: {
        title: 'Анкета участника',
        subtitle: '',
        action: 'Отправить',
      },
      showQuestionnaireSaved: false,
      taskDetailsModal: false,
      taskDetailsState: 'available',
      taskDetailsExerciseId: '',
      taskDetailsOptions: {},
      pageScrollY: 0,
      distanceCharityModal: false,
      distanceCharityType: '',
      distanceCharityLocalizedText: {
        title: '',
        subtitle: '',
      },
      totalDistance: 0,
      completedDistance: 0,
      goalCoords: GoalCoords,
      contributionPercentage: 0,
      avatar: '',
      settingUpGuideOptions: {
        icon: 'fitness-tracker',
        text: 'Подключите трекер, чтобы брать спортивные задания',
        actionText: 'Подключить трекер',
      },
      settingTrainingPlanOptions: {
        icon: 'rocket',
        text: 'Тренируйтесь осмысленно с индивидуальным тренировочным планом',
        actionText: 'Получить план',
        actionIconLeft: 'file-done',
        storageKey: 'hideTreainingBanner',
      },
      settingMarketOptions: {
        icon: 'gift',
        text: 'Заберите свой приветственный бонус',
        actionText: 'Перейти в маркет',
        storageKey: 'hideMarketBanner',
      },
      showInstallNotification: false,
      installNotificationText: `Установите это приложение на iPhone: нажмите<img src="${iOSShare}" />, затем &mdash; На экран "Домой".`,
      loading: true,
      calendarModal: false,
      taskRating: {},
      calendarData: [],
      showNotifications: false,
      hideFeedbackNotificationKey: 'HideFeedbackNotification',
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'options', 'resources']),
    ...mapGetters('user', ['profile', 'isAuthenticated', 'emptyProfile', 'profileAvatar', 'questionnaire', 'questionnaireStatus', 'isMobileApp']),
    ...mapGetters('trackers', ['trackers', 'responseStatus']),
    ...mapGetters('exercises', ['tasksAvailable', 'tasksInProgress', 'tasksCompleted', 'task', 'taskPoints', 'taskGuest', 'responseStatus']),
    ...mapGetters('market', ['promotions']),
    ...mapGetters('achievements', ['achievements']),
    ...mapGetters('operations', ['activityOperations']),
    ...mapGetters('nominations', ['nominationsActive', 'teamNominationsActive']),
    ...mapGetters('goals', ['goal']),
    ...mapGetters('calendar', ['calendar']),
    ...mapGetters('trainings', ['sportIndexes']),
    ...mapGetters('content', ['banners']),
    ...mapGetters('steps', ['steps', 'stepsHistory']),
    ...mapGetters('participant', ['trainingPlan']),
    isSpecialSettingsMp() {
      return this.options ? this.options.isSpecialSettingsMp === true : false;
    },
    isFirstLogin() {
      return this.$route.query.isFirstLogin === 'true';
    },
    isShowQuestionnaire() {
      return this.$route.query.isShowQuestionnaire === 'true';
    },
    isTrackerConnect() {
      return this.$route.name === 'TrackerConnect' && this.$route.query.connect;
    },
    isTrackerConnected() {
      return this.trackers ? this.trackers.filter((i) => i.state === 'Connected').length > 0 : false;
    },
    isTeamSet() {
      return this.profile ? this.profile.team !== undefined && this.profile.team.teamId !== undefined : false;
    },
    profileCreatedOn() {
      return this.profile ? this.profile.createdOn : null;
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    showTeamRatings() {
      return this.options ? this.options.showTeamRatings === true : false;
    },
    disableTeamsForGuest() {
      return this.options ? this.options.disableTeamsForGuest === true : false;
    },
    standardWelcomeModal() {
      return this.options ? this.options.customWelcomeModal !== true : true;
    },
    showWelcomeModalMarket() {
      return this.options ? this.options.showWelcomeModalMarket === true : false;
    },
    isShowMarketBanner() {
      return this.options ? this.options.showMarketOnDashboard === true : false;
    },
    showIndexOFP() {
      return this.options ? this.options.showIndexOFP === true : false;
    },
    questionnaireQuestionsCount() {
      return this.options ? this.options.questionnaireQuestionsCount || 3 : 3;
    },
    questionnaireAnswerCost() {
      return this.options ? this.options.questionnaireAnswerCost || 1 : 1;
    },
    showCalendarBanner() {
      return this.options ? this.options.showCalendarBanner === true : false;
    },
    isFeedbackEnabled() {
      return this.options ? this.options.isFeedbackEnabled === true : false;
    },
    goalModalSubtitle() {
      return this.resources ? this.resources.goalModalSubtitle || '' : '';
    },
    bannerDesktop() {
      return this.resources ? this.resources.communityBanner || '' : '';
    },
    bannerMobile() {
      return this.resources ? this.resources.communityBannerMobile || '' : '';
    },
    bannerDesktopVk() {
      return this.resources ? this.resources.vkBanner || '' : '';
    },
    bannerMobileVk() {
      return this.resources ? this.resources.vkBannerMobile || '' : '';
    },
    bannerVkLink() {
      return this.resources ? this.resources.vkBannerLink || '' : '';
    },
    calendarBanner() {
      return this.resources ? this.resources.calendarBanner || '' : '';
    },
    calendarBannerMobile() {
      return this.resources ? this.resources.calendarBannerMobile || '' : '';
    },
    goalGraph() {
      return this.resources ? this.resources.goalGraph || '' : '';
    },
    goalGraphMobile() {
      return this.resources ? this.resources.goalGraphMobile || '' : '';
    },
    goalTriangle() {
      return this.resources ? this.resources.goalTriangle || '' : '';
    },
    cols() {
      return {
        default: '1', md: '2', lg: '3', xl: '3',
      };
    },
    noPagination() {
      return true;
    },
    activityNavigationOffset() {
      return {
        default: '0', md: '67', lg: '67', xl: '101',
      };
    },
    activityPaginationOffset() {
      return {
        default: '8', md: '12', lg: '12', xl: '28',
      };
    },
    nominationsNavigationOffset() {
      return {
        default: '0', md: '167', lg: '167', xl: '167',
      };
    },
    nominationsPaginationOffset() {
      return {
        default: '8', md: '12', lg: '12', xl: '28',
      };
    },
    hasNominations() {
      if (this.nominationsActive) {
        if (this.nominationsActive.total > 0) return true;
      }
      if (this.teamNominationsActive) {
        if (this.teamNominationsActive.total > 0) return true;
      }
      return false;
    },
    bannerCalendar() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'calendar')[0] : {};
    },
    bannerVk() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'vk')[0] : {};
    },
    bannerCommunity() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'community')[0] : {};
    },
    isShowGoal() {
      return this.options ? this.options.showGoal !== false : true;
    },
    showTrainings() {
      return this.options ? this.options.showTrainings === true : true;
    },
    getEmail() {
      if (this.profile) {
        return this.profile.emailAddress;
      }
      return '';
    },
  },
  watch: {
    themeDone(val) {
      if (val) {
        if (this.showIndexOFP) this.getIndexes();
      }
    },
    nominationsUpdating(val) {
      if (val) this.hiddenNominationsSlider = true;
      else {
        setTimeout(() => {
          this.nominationsSliderHeight = 0;
          this.hiddenNominationsSlider = false;
        }, 500);
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('user', ['getQuestionnaire', 'applyAnswers', 'setAvatar']),
    ...mapActions('trackers', ['getTrackers']),
    ...mapActions('exercises', ['getTasksAvailable', 'getTasksInProgress', 'getTasksCompleted', 'getTask', 'getTaskPoints', 'getTaskGuest', 'assignTask']),
    ...mapActions('market', ['getPromotions']),
    ...mapActions('achievements', ['getAchievements']),
    ...mapActions('operations', ['getActivityOperations']),
    ...mapActions('nominations', ['getNominationsActive', 'getTeamNominationsActive']),
    ...mapActions('goals', ['getGoal']),
    ...mapActions('calendar', ['getCalendar']),
    ...mapActions('trainings', ['getIndexes']),
    ...mapActions('partners', ['createActivity', 'inviteActivity']),
    ...mapActions('content', ['getBanners']),
    ...mapActions('participant', ['physicalLevel', 'sendTrennings', 'getTrainingPlan', 'sendTrenningsPurchase']),
    ...mapActions('steps', ['getSteps', 'getStepsHistory', 'setTargetSteps']),
    onMarketOpen() {
      const idCategory = '1e23dcc6-02e3-4d82-9e5d-a64054069df2/35955166-4d6c-4b2f-b3a4-415ecdf3308f?category=20e8cf8f-30c9-4efa-9e16-53044d2bf6b5';
      this.$router.push({ path: `/market/${idCategory}` }).catch(() => {});
    },
    onTargetStepsInfoClose() {
      this.showTargetStepsInfo = false;
    },
    onSetTarget() {
      this.setTargetSteps({ targetCount: this.stepsCount })
        .then(() => {
          this.getSteps();
          this.fetchStepsHistory();
        }).finally(() => {
          this.showTargetSteps = false;
          this.showAlertTargetSteps = true;
        });
    },
    fetchStepsHistory() {
      const dateTo = dayjs().format('YYYY-MM-DD');
      const dateFrom = dayjs().subtract(30, 'day').format('YYYY-MM-DD');

      this.getStepsHistory({ dateFrom, dateTo });
    },
    onShowTargetSteps() {
      this.showTargetSteps = true;
      this.stepsCount = this.steps ? this.steps.target : this.stepsCount;
    },
    onAddCount() {
      this.stepsCount += 1000;
    },
    onDiminishCount() {
      if (this.stepsCount < 1) return;
      this.stepsCount -= 1000;
    },
    clickSendTrenningsPurchase(type) {
      this.sendTrenningsPurchase({ tariffName: type });
      this.modalTrainingsSuccess = true;
    },
    closeModalTrainingsSuccess() {
      this.modalTrainingsSuccess = false;
    },

    bannerTreanningClose() {
      this.bannerTraining = false;
      sessionStorage.setItem('bannerTraining', false);
      if (!this.isTrackerConnected) {
        this.bannerTracker = true;
        sessionStorage.setItem('bannerTracker', true);
      } else {
        this.bannerTracker = false;
        sessionStorage.setItem('bannerTracker', false);
      }
    },

    bannerTrackerClose() {
      this.bannerTracker = false;
      sessionStorage.setItem('bannerTracker', false);
    },

    stateBannerTranning() {
      if (this.isShowMarketBanner && !this.profile.hasParticipantAnyPurchase) {
        this.bannerMarket = true;
      } else if (Object.keys(this.trainingPlan).length === 0 && this.showTrainings) {
        this.bannerTraining = true;
      } else if (!this.isTrackerConnected) {
        if (this.trackers) {
          this.bannerTracker = true;
          sessionStorage.setItem('bannerTracker', true);
        } else {
          this.bannerTracker = false;
          sessionStorage.setItem('bannerTracker', false);
        }
      }
    },

    bannerMarketClose() {
      this.bannerMarket = false;
      sessionStorage.setItem('bannerMarket', false);
      if (Object.keys(this.trainingPlan).length === 0) {
        this.bannerTraining = true;
        sessionStorage.setItem('bannerTraining', true);
      }
      if (!this.isTrackerConnected) {
        this.bannerTracker = true;
        sessionStorage.setItem('bannerTracker', true);
      } else {
        this.bannerTracker = false;
        sessionStorage.setItem('bannerTracker', false);
      }
    },
    onQuizEnd(data) {
      if (data.key) {
        this.quizModal = false;
        this.modalTrainingsFinish = true;
        this.sendTrennings({ trainingPlan: data.key });
        this.getTrainingPlan();
      }
    },
    onTargetStepsClose() {
      this.showTargetSteps = false;
    },
    onStartQuiz() {
      const options = {};
      options.quizId = 86257;
      if (options.quizId) {
        this.quizOptions = options;
        this.modalTrainings = false;
        this.modalTrainingsInfo = false;
        this.quizModal = true;
      }
    },
    onQuizClose() {
      this.quizModal = false;
    },
    onQuizBack() {
      this.quizModal = false;
    },
    onModalTracker() {
      if (this.$root.showOnboarding) {
        localStorage.removeItem('returnToTask');
        this.$root.showOnboarding({ step: 'choose' });
      }
    },
    onModalTrainingsOpen() {
      this.modalTrainings = true;
    },
    onStartPrice() {
      this.modalTrainingsFinish = false;
      this.modalTrainingsPrice = true;
    },
    onModalTrainingsInfoOpen() {
      if (this.modalTrainings) {
        this.modalTrainings = false;
        this.modalTrainingsInfo = true;
      }
    },
    checkProfile() {
      this.getProfile()
        .then(() => {
          if (this.emptyProfile === false && !this.profileAvatar) {
            this.setAvatar({ avatarType: 3 });
          }
          const refValue = sessionStorage.getItem('ref');
          const participantId = this.profile ? this.profile.participantId : undefined;
          if (refValue !== null) {
            const payload = {
              activityId: uuidv4(),
              participantExerciseTaskId: refValue,
              participantId,
            };
            if (participantId) {
              this.inviteActivity(payload)
                .then(() => {
                  sessionStorage.removeItem('ref');
                });
              sessionStorage.removeItem('ref');
            }
          }
          if (this.isAuthenticated) {
            localStorage.removeItem('authEmail');
            localStorage.removeItem('authToken');
            localStorage.removeItem('authDate');
            sessionStorage.removeItem('invitationTeamId');
            sessionStorage.removeItem('marketingProgramCode');
            sessionStorage.removeItem('authFromSSO');
            sessionStorage.removeItem('ssoAccessAttributes');
            if (this.emptyProfile === true) {
              this.$router.push({ name: 'Register' }).catch(() => {});
            } else if (this.isFirstLogin) {
              this.showWelcome();
            } else if (this.isShowQuestionnaire) {
              this.showQuestionnaire();
            } else if (this.isTrackerConnect) {
              this.showTrackerConnect();
            }
            this.avatar = this.getAvatarImage(this.profile ? this.profile.avatar : null);
            this.getBalance();
            this.getAchievements();
            this.getTasksAvailable({ offset: 0, count: 12 });
            this.getTasksInProgress({ offset: 0, count: 12 });
            this.getTasksCompleted({ offset: 0, count: 12 });
            this.getPromotions({ offset: 0, count: 12 });
            this.getCalendarBanner();
            this.getTrackers()
              .then(() => {
                this.stateBannerTranning();
                this.getNominationsActive({ offset: 0, count: 12 })
                  .then(() => {
                    if (this.isTeamsEnabled && this.showTeamRatings) {
                      this.getTeamNominationsActive({ offset: 0, count: 12 })
                        .then(() => {
                          if (this.nominationsActive ? this.nominationsActive.total === 0 : false) {
                            if (this.teamNominationsActive ? this.teamNominationsActive.total > 0 : false) {
                              this.onChangeNominationType(1);
                            } else {
                              this.onChangeNominationType(0);
                            }
                            window.addEventListener('hashchange', this.onHashChange);
                            this.onHashChange();
                            return;
                          }
                          this.onChangeNominationType(0);

                          window.addEventListener('hashchange', this.onHashChange);
                          this.onHashChange();
                        });
                      return;
                    }
                    this.onChangeNominationType(0);

                    window.addEventListener('hashchange', this.onHashChange);
                    this.onHashChange();
                  });
              });
            this.getGoal()
              .then(() => {
                if (this.goal) {
                  this.contributionPercentage = Math.round(this.goal.contribution.contributionPercentage);
                  this.totalDistance = this.goal.contribution.totalDistance;
                  this.completedDistance = this.goal.completedDistance;
                }
              });
            this.getActivityOperations({ offset: 0, count: 25 })
              .then(() => {
                this.activities = this.getMapppedOperations(this.activityOperations.values);
                setTimeout(() => { this.loading = false; }, 3000);
              });

            const isIos = () => {
              const userAgent = window.navigator.userAgent.toLowerCase();
              return /iphone|ipad|ipod/.test(userAgent);
            };
            const isSafari = () => navigator.vendor.match(/apple/i);
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
            if (isIos() && isSafari && !isInStandaloneMode() && !this.isMobileApp) {
              setTimeout(() => {
                this.showInstallNotification = true;
                setTimeout(() => { this.showInstallNotification = false; }, 15000);
              }, 5000);
            }

            setTimeout(() => { this.checkAndShowFeedback(); }, 3000);
          }
        });
    },
    showWelcome() {
      this.welcomeLocalizedText.title = `${this.profile.firstName || ''}, вы участник!`;
      if (!this.standardWelcomeModal) {
        this.welcomeLocalizedText.subtitle = `Давайте познакомимся поближе — заполните анкету и получите <b>по <img src="${Coin}"/> ${this.questionnaireAnswerCost} за каждый ответ.</b>`;
      }
      this.welcomeModal = true;
    },
    onWelcomeClosed() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/dashboard?isFirstLogin=true#closed').catch(() => {});
        return;
      }
      this.welcomeModal = false;
    },
    onWelcomeActionPrimary() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/dashboard?isShowQuestionnaire=true').catch(() => {});
        this.showQuestionnaire();
        return;
      }
      window.location.href = '/dashboard?isShowQuestionnaire=true';
      this.welcomeModal = false;
    },
    onWelcomeActionSecondary() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/dashboard?isFirstLogin=true#closed').catch(() => {});
        return;
      }
      if (!this.showWelcomeModalMarket) {
        window.location.href = '/dashboard';
      } else window.location.href = '/about#textBlock';
      this.welcomeModal = false;
    },
    onWelcomeActionMarket() {
      if (this.isMobileApp) {
        this.welcomeModal = false;
        this.$router.replace('/dashboard?isFirstLogin=true#closed').catch(() => {});
        return;
      }
      if (!this.standardWelcomeModal) {
        this.welcomeModal = false;
        const idCategory = '%5B%22cc5d50f0-a7a6-4d3d-bdd0-d09daf078014%22%5D';
        setTimeout(() => {
          window.location.href = `/market?category=${idCategory}`;
        }, 1000);
      }
    },
    showQuestionnaire() {
      this.questionnaireLocalizedText.subtitle = `Ответьте на <b>${this.questionnaireQuestionsCount} вопроса(ов)</b>, чтобы получить <img src="${Coin}"/> <b>${this.questionnaireQuestionsCount * this.questionnaireAnswerCost}</b>`;
      this.questionnaireModal = true;
      this.getQuestionnaire();
    },
    showTrackerConnect() {
      this.$root.showOnboarding({ step: 'finish' });
    },
    onQuestionnaireSave(answers) {
      const data = {};
      for (let i = 0; i < answers.length; i += 1) {
        if (answers[i] !== null) data[`Question${i + 1}`] = answers[i].toString();
      }
      this.applyAnswers(data)
        .then(() => {
          if (this.questionnaireStatus === 204) {
            window.removeEventListener('hashchange', this.onHashChange);
            this.questionnaireModal = false;
            setTimeout(() => {
              if (this.isMobileApp) {
                this.questionnaireModal = false;
                this.$router.replace('/dashboard?isShowQuestionnaire=true#saved').catch(() => {});
                return;
              }
              window.location.href = '/dashboard#!showQuestionnaireSaved=true';
            }, 250);
          }
        });
    },
    onQuestionnaireClosed() {
      if (this.isMobileApp) {
        this.questionnaireModal = false;
        this.$router.replace('/dashboard?isShowQuestionnaire=true#closed').catch(() => {});
        return;
      }
      window.location.href = '/dashboard';
      this.questionnaireModal = false;
    },
    onGoTo(routeName) {
      if (routeName) this.$router.push({ name: routeName }).catch(() => {});
    },
    onSliderIndexChaged() {
      scrollToId('dashboard-activity');
    },
    onBanner() {
      this.$router.push({ name: 'Community' }).catch(() => {});
    },
    getCalendarBanner() {
      this.getCalendar()
        .then(() => {
          if (this.responseStatus === 200) {
            this.calendarData = this.calendar ? this.calendar : [];
          }
        });
    },
    onStart() {
      this.taskDetailsModal = false;
      window.location.hash = '';
      if (this.taskDetailsOptions) {
        if (this.taskDetailsOptions.isTrackerNeeded !== true) {
          const participantExerciseId = uuidv4();
          this.assignTask({ participantExerciseId, exerciseId: this.taskDetailsExerciseId })
            .then(() => {
              if (this.responseStatus === 204) {
                window.scrollTo(0, 0);
                const url = `/tasks/in-progress#!showTask=${this.taskDetailsExerciseId}&peId=${participantExerciseId}`;
                this.$router.replace(url);
              }
            });
          return;
        }
      }
      if (this.trackers.filter((i) => i.state === 'Connected').length > 0) {
        const participantExerciseId = uuidv4();
        this.assignTask({ participantExerciseId, exerciseId: this.taskDetailsExerciseId })
          .then(() => {
            if (this.responseStatus === 204) {
              window.scrollTo(0, 0);
              const url = `/tasks/in-progress#!showTask=${this.taskDetailsExerciseId}&peId=${participantExerciseId}`;
              this.$router.replace(url);
            }
          });
        return;
      }
      if (this.$root.showOnboarding) {
        localStorage.setItem('returnToTask', this.taskDetailsExerciseId);
        this.$root.showOnboarding({ step: 'choose' });
      }
    },
    onAction(i) {
      this.$router.push({ name: 'RewardDetails', query: { id: this.promotions.values[i].promotionId, from: 'Dashboard' } }).catch(() => {});
    },
    onShowTask(index) {
      const tasks = this.tasksAvailable.values;
      if (tasks) {
        const task = tasks[index];
        localStorage.removeItem('returnToTask');
        window.location.hash = `#!showTask=${task.exerciseId}`;
      }
    },
    onTaskDetailsClose() {
      this.taskDetailsModal = false;
      window.scroll(0, this.pageScrollY);
      window.location.hash = '';
    },
    onHashChange() {
      if (this.$route.name !== 'Dashboard') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showTask') this.processLoadTask(hash);
      else if (hash[0] === '#!showQuestionnaireSaved') this.processQuestionnaire();
      else if (hash[0] === '#!showNomination') this.showNomination(hash);
      else if (hash[0] === '#!showGoal') this.showGoal(hash);
      else if (hash[0] === '#!showCalendar') this.showCalendar(hash);
      else {
        this.taskDetailsModal = false;
        this.showQuestionnaireSaved = false;
        this.rulesPrizesModal = false;
        this.distanceCharityModal = false;
        this.calendarModal = false;
      }
    },
    onShowRules(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = false;
      this.rulesPrizesTabActive = 0;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
    },
    onShowPrizes(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = false;
      this.rulesPrizesTabActive = 1;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
    },
    onShowRating(item, isTeam = false) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesTabActive = 2;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesIsTeam = isTeam;
      this.rulesPrizesModal = true;
    },
    onRulesPrizesClosed() {
      window.location.hash = '';
      this.rulesPrizesModal = false;
    },
    onShowOnboarding() {
      this.$root.showOnboarding({ step: 'choose' });
    },
    onShowDistance() {
      window.location.hash = '#!showGoal=true';
      this.distanceCharityType = 'distance';
      this.distanceCharityLocalizedText = {
        title: 'Вместе преодолеть',
        subtitle: this.goalModalSubtitle ? this.goalModalSubtitle : 'Бегайте и ходите. Внесите свой вклад в достижение общей цели.',
      };
      this.distanceCharityModal = true;
    },
    onDistanceCharityModalClosed() {
      window.location.hash = '#!showGoal=false';
    },
    onChangeNominationType(index) {
      if (this.selectedNominationType === index) return;
      this.updateHeight();
      this.selectedNominationType = -1;
      setTimeout(() => { this.nominationsUpdating = true; }, 100);
      setTimeout(() => {
        this.selectedNominationType = index;
        this.nominationsUpdating = false;
      }, 400);
    },
    onFeedback() {
      if (!window.Userback) return;
      this.showNotifications = false;
      window.Userback.open('general');
      this.setUserbackEventListener();
    },
    onUserbackSend() {
      this.showNotifications = false;
      // Скрыть уведомление на 30 суток
      const date = (Date.now() + 30 * 24 * 60 * 60 * 1000).toString();
      this.saveDuration(date);
    },
    processLoadTask(hash) {
      if (hash.length === 2) {
        this.loadTask(hash[1]);
      }
      if (hash.length === 3 && hash[1].indexOf('&peId') >= 0) {
        this.loadMyTask(hash[1].split('&peId')[0], hash[2]);
      }
    },
    processQuestionnaire() {
      this.showQuestionnaireSaved = true;
      setTimeout(() => { window.location.hash = ''; }, 1000);
    },
    showNomination(hash) {
      if (!hash[1]) {
        this.rulesPrizesModal = false;
        this.clearHash();
        return;
      }
      if (hash[1] === 'false') {
        this.rulesPrizesModal = false;
        this.clearHash();
        return;
      }

      const nominationId = hash[1] || '';
      if (!nominationId) {
        this.clearHash();
        return;
      }

      const personal = this.nominationsActive ? this.nominationsActive.values.filter((i) => i.nominationId === nominationId) : [];
      if (personal.length > 0) {
        this.onShowRating(personal[0]);
        return;
      }

      const team = this.teamNominationsActive ? this.teamNominationsActive.values.filter((i) => i.nominationId === nominationId) : [];
      if (team.length > 0) {
        this.onShowRating(team[0]);
      }
    },
    showGoal(hash) {
      if (hash[1] === 'true') this.onShowDistance();
      else {
        this.distanceCharityModal = false;
        this.clearHash();
      }
    },
    showCalendar(hash) {
      if (hash[1] === 'true') this.getCalendarBanner();
      else {
        this.calendarModal = false;
        this.clearHash();
      }
    },
    loadTask(id) {
      this.getTaskGuest(id)
        .then(() => {
          if (this.taskGuest) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = 'available';
            this.taskDetailsExerciseId = id;
            this.taskDetailsOptions = this.taskGuest;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          window.location.hash = '';
        });
    },
    loadMyTask(id, peId) {
      this.getTask(peId)
        .then(() => {
          if (this.task) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = this.getTaskDetailsState(this.task);
            this.taskDetailsExerciseId = peId;
            this.taskDetailsOptions = this.task;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          window.location.hash = `#!showTask=${id}`;
        });
    },
    getTaskReward(task) {
      let reward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
      }
      return Math.round(reward);
    },
    getTaskRewardRange(task) {
      let reward = 0;
      let maxReward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
        maxReward += task.tasks[i].maxReward;
      }
      return maxReward && reward < maxReward ? `${Math.round(reward)}-${maxReward}` : Math.round(reward);
    },
    getIsFlexibleCost(task) {
      const reward = this.getTaskReward(task);
      if (!task || !task.tasks) return false;
      let maxReward = 0;
      for (let i = 0; i < task.tasks.length; i += 1) {
        maxReward += task.tasks[i].maxReward;
      }
      return reward < maxReward;
    },
    isRepeat(task) {
      return task.type === 'Reusable';
    },
    hasSameTimeAndType(operation1, operation2) {
      if (!operation1 || !operation2) {
        return false;
      }
      if (operation1.operationType !== 'SportActivity' || operation2.operationType !== 'SportActivity') {
        return false;
      }
      const date1 = dayjs(operation1.operationDate);
      const date2 = dayjs(operation2.operationDate);
      if (!date1.isValid() || !date2.isValid()) {
        return false;
      }
      return date1.isSame(date2, 'second');
    },
    filterOperationsByTime(operations) {
      const filteredOperations = [];
      let previousOperation = null;
      for (let i = 0; i < operations.length; i += 1) {
        const currentOperation = operations[i];
        if (previousOperation && this.hasSameTimeAndType(previousOperation, currentOperation)) {
          console.log(previousOperation, currentOperation);
          previousOperation.title += `, ${this.getFormattedDistance(currentOperation)}`;
        } else {
          filteredOperations.push(currentOperation);
          previousOperation = currentOperation;
        }
      }
      return filteredOperations;
    },
    getMapppedOperations(operations) {
      if (!operations) return [];
      const filteredOperations = this.filterOperationsByTime(operations);
      const days = [];
      let currentDay = { date: '', items: [] };
      let sportActivities = [];
      let lastDate = '';
      for (let i = 0; i < filteredOperations.length; i += 1) {
        const value = filteredOperations[i];
        const date = dayjs.utc(value.operationDate).local();
        if (!date.isValid()) continue;
        const formattedDate = date.format('D MMMM');
        const item = this.getMappedItem(value);
        const currentDate = date.format('YYYY-MM-DD');
        if (currentDate !== lastDate) {
          if (lastDate !== '') {
            days.push(currentDay);
          }
          currentDay = { date: formattedDate, items: [] };
          sportActivities = [];
        }
        if (value.operationType === 'SportActivity') {
          const existingSportActivity = sportActivities.find((activity) => activity.time === item.time);
          if (existingSportActivity) {
            existingSportActivity.titles = existingSportActivity.titles || [];
            existingSportActivity.titles.push(item.title);
            existingSportActivity.title = `Получены новые тренировки: ${new Intl.ListFormat('ru').format(existingSportActivity.titles)}`;
          } else {
            item.titles = [item.title];
            item.title = `Получены новые тренировки: ${item.title}`;
            currentDay.items.push(item);
            sportActivities.push(item);
          }
        } else {
          currentDay.items.push(item);
        }
        lastDate = currentDate;
      }
      if (currentDay.items.length > 0) {
        days.push(currentDay);
      }
      return days;
    },
    pluralizeSteps(count) {
      if (count % 10 === 1 && count % 100 !== 11) {
        return `<b>${count}</b> шаг`;
      } if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        return `<b>${count}</b> шага`;
      }
      return `<b>${count}</b> шагов`;
    },
    getMappedItem(value) {
      const item = {};
      if (value.operationType === 'TeamParticipant') {
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.img = value.attributes.TeamLogo || '';
        if (value.attributes.StateOperation === 'Created') {
          item.title = `Вы присоединились к команде <a href="/my-team">${value.attributes.TeamName}`;
        } else {
          item.title = `Вы покинули команду <span class="team-out">${value.attributes.TeamName}</span>`;
          item.isTaskRejected = true;
        }
        return item;
      }
      if (value.operationType === 'Purchase') {
        item.img = value.attributes.ProductMobileImageUrl || '';
        item.isInternalImg = false;
        item.title = `Вы купили <a href="/market/reward?id=${value.attributes.PromotionId || ''}">${value.attributes.ProductName}`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.coins = -Math.round(value.attributes.Amount);
        return item;
      }
      if (value.operationType === 'ParticipantExercise') {
        if (!['RejectedManually', 'Rejected'].includes(value.attributes.StateOperation)) {
          item.img = value.attributes.ExerciseMobileImageUrl || '';
          item.isInternalImg = false;
          item.title = `${this.getFormattedTaskState(value)} <a href="#!showTask=${value.sourceId}&peId=${value.operationSourceId}">${value.attributes.ExerciseTitle}</a>`;
          item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
          if (value.attributes.StateOperation === 'Completed') {
            item.coins = `+${Math.round(value.attributes.Reward)}`;
          }
          item.points = 0;
          return item;
        }
        item.img = value.attributes.ExerciseMobileImageUrl || '';
        item.isInternalImg = false;
        item.isTaskRejected = true;
        item.title = `Отменено задание <a href="#!showTask=${value.sourceId}">${value.attributes.ExerciseTitle}</a>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'SportActivity') {
        item.img = ActivityCover3;
        item.isInternalImg = true;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.title = `<a href="/my-settings/trainings">${this.getFormattedDistance(value)}</a>`;
        return item;
      }
      if (value.operationType === 'Achievement') {
        const achievement = this.getAchievement(value);
        item.img = achievement.img;
        item.isInternalImg = true;
        item.achievementType = value.attributes.AchievementType;
        item.title = `Вы разблокировали достижение <b>${achievement.name}</b>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'ParticipantExperienceLevel') {
        item.img = ActivityCoverXP;
        item.isInternalImg = true;
        item.title = `Вы достигли <b>${value.attributes.Level} ур.</b>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'ParticipantSteps') {
        item.isInternalImg = false;
        item.img = '';
        item.isTaskSteps = true;
        item.StepsCount = Math.round(value.attributes.StepsCount);
        item.title = `Вы прошли ${this.pluralizeSteps(item.StepsCount)}`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.coins = `+${Math.round(value.attributes.AccruedPoints)}`;
        return item;
      }
      item.img = '';
      item.isInternalImg = false;
      item.achievementType = '';
      item.title = '';
      item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
      return item;
    },
    getFormattedDistance(value) {
      if (!value || !value.attributes) return '';
      const distance = parseInt(value.attributes.Distance, 10);
      const unit = distance >= 1000 ? 'км' : 'м';
      const formatted = distance >= 1000 ? Math.round(distance / 1000) : distance;
      if (value.attributes.Discipline === 'Walking') return `${formatted} ${unit} ходьба`;
      if (value.attributes.Discipline === 'Running') return `${formatted} ${unit} бег`;
      if (value.attributes.Discipline === 'Cycling') return `${formatted} ${unit} велозаезд`;
      if (value.attributes.Discipline === 'Swimming') return `${formatted} ${unit} плавание`;
      if (value.attributes.Discipline === 'Skiing') return `${formatted} ${unit} лыжи`;

      return '';
    },
    getFormattedTaskState(value) {
      if (!value || !value.attributes) return '';
      if (value.attributes.StateOperation === 'Created') return 'Вы начали задание';
      if (value.attributes.StateOperation === 'Completed') return 'Вы успешно выполнили задание';
      return '';
    },
    getAchievement(value) {
      if (!value || !value.attributes) return '';
      const found = this.achievementsAll.filter((i) => i.achievementType === value.attributes.AchievementType);
      if (found.length === 0) return { name: '', img: '' };
      return found[0];
    },
    getTaskDetailsState(task) {
      if (!task.participantExercise) return 'available';
      if (task.participantExercise.state === 'Created') return 'inProgress';
      if (task.participantExercise.state === 'Completed') return 'completed';
      if (task.participantExercise.state === 'Rejected') return 'failed';
      if (task.participantExercise.state === 'AwaitingConfirmation') return 'checking';
      return '';
    },
    getNominationImage(item) {
      if (!item || !item.images) return '';
      return this.$root.isDesktop ? item.images.desktop || '' : item.images.mobile || '';
    },
    getNominationDates(item) {
      if (!item) return '';
      const startDate = dayjs.utc(item.startDate);
      const finishDate = dayjs.utc(item.finishDate);
      if (startDate.isValid() && finishDate.isValid()) {
        return formatDateRange({ start: startDate, finish: finishDate, showYear: true });
      }
      return '';
    },
    getNominationRating(item, isTeam = false) {
      if (!item) return [];
      const rating = [];
      for (let i = 0; i < item.leaders.length; i += 1) {
        const it = {
          number: item.leaders[i].position.toString(),
          name: isTeam ? item.leaders[i].teamName : `${item.leaders[i].lastName} ${item.leaders[i].firstName}`,
          value: item.leaders[i].points.toString(),
          current: isTeam ? item.leaders[i].isCurrentTeam === true : item.leaders[i].isCurrentUser === true,
        };
        rating.push(it);
      }
      return rating;
    },
    getAvatarImage(avatar) {
      if (!avatar) return '';
      if (!avatar.type) return '';
      if (avatar.type === 'custom') {
        return avatar.url || '';
      }
      const found = Avatars.filter((i) => i.id === avatar.type);
      if (found.length === 0) return '';
      return found[0].img || '';
    },
    updateHeight() {
      const itemsSlider = document.getElementById(this.nominationsSliderId);
      if (itemsSlider === null) return;
      if (itemsSlider.offsetHeight > 0) {
        this.nominationsSliderHeight = itemsSlider.offsetHeight;
      }
    },
    isRatingTypeDisabled(index) {
      if (index === 0) {
        if (this.nominationsActive ? this.nominationsActive.total === 0 : true) return true;
      }
      if (index === 1) {
        if (this.isTeamRatingsDisabled()) return true;
        if (this.teamNominationsActive ? this.teamNominationsActive.total === 0 : true) return true;
      }
      return false;
    },
    isTeamRatingsDisabled() {
      return !this.isTeamSet && this.disableTeamsForGuest;
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
    setBannersBlocks() {
      this.getBanners()
        .then(() => {
          if (this.contentStatus === 200) {
            this.bannersItems = this.banners || [];
          }
        });
    },
    checkAndShowFeedback() {
      this.checkResetFeedbackNotificationDate();

      if (!this.isFeedbackEnabled || this.isMobileApp) return;

      this.getQuestionnaire()
        .then(() => {
          if (this.questionnaireStatus === 200) {
            const isAnyDialogShown = this.isAnyDialogShown();
            if (!this.questionnaire.answers) return;
            const found = this.questionnaire.answers.filter((i) => i.key === this.hideFeedbackNotificationKey);
            if (found.length === 0) {
              if (!isAnyDialogShown) {
                this.showNotifications = true;
                setTimeout(() => { this.showNotification(); }, 100);
              }
              return;
            }

            const duration = found[0].value;
            if (Date.now() >= parseInt(duration, 10)) {
              if (!isAnyDialogShown) {
                this.showNotifications = true;
                setTimeout(() => { this.showNotification(); }, 100);
              }
            }
          }
        });
    },
    showNotification() {
      const notification = {
        props: {
          title: 'Оцените СКАУНТ',
          text: 'Оцените удобство использования программы',
          img: '/img/feedback.svg',
          declineText: 'Не сейчас',
          confirmText: 'Оценить',
        },
        confirm: () => {
          // Показать виджет Userback
          this.onFeedback();
        },
        decline: () => {
          // Скрыть уведомление на 7 суток
          const date = (Date.now() + 7 * 24 * 60 * 60 * 1000).toString();
          this.saveDuration(date);
        },
        dismiss: () => {
          // Скрыть уведомление на 7 суток
          const date = (Date.now() + 7 * 24 * 60 * 60 * 1000).toString();
          this.saveDuration(date);
        },
      };

      if (this.profileCreatedOn) {
        const created = dayjs.utc(this.profileCreatedOn);
        if (created.isValid()) {
          const days = dayjs.utc().diff(created, 'days');
          if (days < 1) return;
        }
      }

      if (this.$root.$addNotification && !this.isAnyDialogShown()) {
        this.$root.$addNotification(notification);
        this.checkAndHideNotification();
      }
    },
    saveDuration(duration) {
      const answers = {};
      answers[this.hideFeedbackNotificationKey] = duration;
      this.applyAnswers(answers)
        .then(() => {
          if (this.questionnaireStatus === 204) {
            this.getQuestionnaire();
          }
        });
    },
    isAnyDialogShown() {
      return this.welcomeModal || this.questionnaireModal || this.taskDetailsModal || this.rulesPrizesModal
        || this.distanceCharityModal || this.modalTrainings || this.modalTrainingsInfo || this.modalTrainingsFinish
        || this.modalTrainingsPrice || this.quizModal || document.body.classList.contains('has-modal');
    },
    checkAndHideNotification() {
      if (this.$route.name !== 'Dashboard') return;
      if (this.isAnyDialogShown()) this.showNotifications = false;
      setTimeout(() => { this.checkAndHideNotification(); }, 100);
    },
    setUserbackEventListener() {
      if (this.$route.name !== 'Dashboard') return;
      const container = document.getElementById('userback_button_container');
      if (!container) {
        setTimeout(() => { this.setUserbackEventListener(); }, 50);
        return;
      }
      const button = container.querySelector('button.userback-controls-send.userback-button-input');
      if (!button) {
        setTimeout(() => { this.setUserbackEventListener(); }, 50);
        return;
      }
      button.addEventListener('click', this.onUserbackSend);
    },
    checkResetFeedbackNotificationDate() {
      const itemName = 'resetFeedbackNotificationDate';
      const reset = localStorage.getItem(itemName) === 'true';
      if (!reset) return;
      const date = '0';
      this.saveDuration(date);
      localStorage.removeItem(itemName);
    },
  },
  mounted() {
    this.getSteps();
    this.fetchStepsHistory();
    this.checkProfile();
    if (this.themeDone) {
      this.setBannersBlocks();
      if (this.showIndexOFP) this.getIndexes();
    }
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Dashboard";
</style>
